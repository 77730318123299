html, body {
  background-color: #FEFAF0;
  height: 100vmin;
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
  margin: 10px;
}

.admin-form {
  width: 400px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.desktop {
  display: none;
}
.mobile {
  display: flex;
}

@media screen and (min-width: 300px) {
  .single-photo {
    max-width: 50%;
  }
  .couple-photos {
    max-width: 80%;
  }

  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }
}

@media screen and (min-width: 975px) {
  .single-photo {
    max-width: 35%;
  }
  .couple-photos {
    max-width: 60%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.month-header {
  position: sticky;
  top: calc(39px + 2vmin);
  width: 100%;
  background-color: #FEFAF0;
  z-index: 0;
  font-size: calc(5px + 2vmin);
  padding-left: 10px;
  font-family: 'Open Sans', sans-serif;
}

.year-header {
  position: sticky;
  top: 0;
  height: calc(40px + 2vmin);
  width: 100%;
  background-color: #FEFAF0;
  z-index: 1;
  font-size: calc(6px + 2vmin);
  padding-left: 10px;
  font-family: 'Open Sans', sans-serif;
}

.sort-order-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FEFAF0;
}

.main-title {
  font-size: calc(2vmin * 4);
  margin-bottom: 0;
  font-family: 'Germania One', cursive;
}

.sub-title {
  font-size: calc(1vmin * 4);
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
}

.ally-logo {
  height: calc(7vmin * 4);
  margin-left: auto;
  margin-right: 20px;
  display: flex;
}

.main-title .element {
  display: flex;
  width: 100vw;
}

.main-title .pet-type {
  margin-right: auto;
  margin-left: 10px;
}

.main-title .pet-name {
  margin: 10px auto;
}

.main-title .pet-age {
  margin-left: auto;
  margin-right: 10px;
}

.sort-order-button {
  margin: auto 10px auto auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  image-orientation: from-image;
}
